<template>
    <div>
        <Slider
            ratio="10by1"
            :image="$options.filters.cdnUrl(firstSlide.image)"
            :title="$t('menu.news')"
        />

        <div class="theme-light bg-white pagebg">
            <div class="c w-xxl">
                <NewsArticles class="mt-xxl" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'News',
    components: {
        NewsArticles: () => import('@/components/NewsArticles.vue'),
        Slider: () => import('@/components/Slider.vue'),
    },
    computed: {
        firstSlide() {
            if (this.$store.state.data.slides && this.$store.state.data.slides.length > 0) {
                return this.$store.state.data.slides[0];
            }
            return {};
        },
    },
};
</script>
